import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/Home";
import ContactUs from "./pages/ContactUs";
import NotfoundPage from "./pages/_404.tsx";
import FederalItSolutions from "./pages/FederalITSolutions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivacyPolicy from "./pages/privacy-policy/index.tsx";
import ReturnPolicy from "./pages/return-policy/index.tsx";
import ShippingPolicy from "./pages/shipping-policy/index.tsx";
import TermAndCondition from "./pages/term-and-condition/index.tsx";
import Shop from "./pages/shop/index.tsx";
import Cart from "./pages/cart/index.jsx";
import CheckeOut from "./pages/checkout/index.tsx";
import Product from "./pages/product/index.tsx";
import ProductCategory from "./pages/product-category/index.tsx";
import IndexNew from "./pages/newIndex/index.tsx";
import { Provider } from "react-redux";
import { store, persistor} from "./store.ts";
import { PersistGate } from "redux-persist/integration/react";
import WhishList from "./pages/wishlist/index.jsx";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },

  {
    path: "contact-us",
    element: <ContactUs />,
  },
  {
    path: "federal-it-solutions",
    element: <FederalItSolutions />,
  },

  {
    path: "*",
    element: <NotfoundPage />,
  },
  // My Paths
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/return-policy",
    element: <ReturnPolicy />,
  },
  {
    path: "/shipping-policy",
    element: <ShippingPolicy />,
  },
  {
    path: "/term-and-condition",
    element: <TermAndCondition />,
  },
  {
    path: "/contact-us",
    element: <ContactUs />,
  },
  {
    path: "/shop",
    element: <Shop />,
  },
  {
    path: "/product-category/:slug",
    element: <ProductCategory />,
  },
  {
    path: "/cart",
    element: <Cart />,
  },

  {
    path: "/checkout",
    element: <CheckeOut />,
  },
  {
    path: "/shop-main",
    element: <IndexNew />,
  },
  {
    path: "/wishlist",
    element: <WhishList />,
  },
  {
    path: "/product/:id",
    element: <Product />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <RouterProvider router={router} />
      <ToastContainer />
    </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
