import React, { useEffect, useRef } from "react";
import cancel from "../../../src/assets/svg/cancel.svg"
interface DialogBoxProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  children: React.ReactNode;
  back?: boolean;
  className?:string;
}

const DialogBox: React.FC<DialogBoxProps> = ({ isOpen, onClose, back = false, children,className }) => {
  const dialogRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleTouchStart = (e: TouchEvent) => {
      // Custom logic if needed
    };

    const handleTouchEnd = (e: TouchEvent) => {
      // Custom logic if needed
    };

    const currentDialogRef = dialogRef.current;

    if (isOpen) {
      if (currentDialogRef) {
        currentDialogRef.focus();
        currentDialogRef.addEventListener("touchstart", handleTouchStart);
        currentDialogRef.addEventListener("touchend", handleTouchEnd);
      }
      // Add class to body to disable scrolling
      document.body.classList.add("overflow-hidden");
    } else {
      // Remove class from body to re-enable scrolling
      document.body.classList.remove("overflow-hidden");
    }

    // Clean up when the component is unmounted or isOpen changes
    return () => {
      if (currentDialogRef) {
        currentDialogRef.removeEventListener("touchstart", handleTouchStart);
        currentDialogRef.removeEventListener("touchend", handleTouchEnd);
      }
      document.body.classList.remove("overflow-hidden");
    };
  }, [isOpen]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      tabIndex={-1}
      ref={dialogRef}
      onKeyDown={handleKeyDown}
    >
      <div className={`bg-white pt-8 rounded-lg shadow-xl w-full max-w-[300px] max-h-screen overflow-y-scroll no-scrollbar mx-4 sm:mx-auto ${className}`}>
        <div className="flex justify-between items-center z-50 px-4 py-2">
          {/* <button
            onClick={onClose}
            className={back ? "text-gray-400 ml-auto hover:bg-app_bg_gray rounded-full duration-100" : "hidden"}
          >
              <img src={cancel} alt="close" className="w-6 h-6" /> 
          </button> */}
        </div>
        <div className="p-4">{children}</div>
      </div>
    </div>
  );
};

export default DialogBox;
