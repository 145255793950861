import React from "react";
import navlist from "../../data/nav.json";
import { Link, useNavigate } from "react-router-dom";
import {useState,useEffect} from "react";
import { SERVER_URL } from "../../constants/apiLinks";
function Footer({ onAboutPress, onServicePress }) {
  const navigation = useNavigate();
  const [navI1, setNavI1] = useState(null);
  const [navI2, setNavI2] = useState(null);
  const [navI3, setNavI3] = useState(null);
  const [navI4, setNavI4] = useState(null);
  const [footerI1, setFooterI1] = useState(null);
  const [footerI2, setFooterI2] = useState(null);
  const [footerI3, setFooterI3] = useState(null);
  const [footerI4, setFooterI4] = useState(null);
  const [navI5, setNavI5] = useState(null);
  const [intialNav, setIntialNav] = useState(navlist);

  const fetchNavItem = async (navItem) => {
    try {
      const response = await fetch(`${SERVER_URL}/api/layout1/${navItem}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) throw new Error(`Failed to fetch ${navItem}`);

      return await response.json();
    } catch (error) {
      console.error(`Error fetching ${navItem}:`, error);
      return null; // Return null if there's an error
    }
  };

  const fetchAllNavItems = async () => {
    const [navI1Data, navI2Data, navI3Data, navI4Data, navI5Data ,footerI1Data,footerI2Data,footerI3Data,footerI4Data] = await Promise.all([
      fetchNavItem('navI1'),
      fetchNavItem('navI2'),
      fetchNavItem('navI3'),
      fetchNavItem('navI4'),
      fetchNavItem('navI5'),
      fetchNavItem('footerI1'),
      fetchNavItem('footerI2'),
      fetchNavItem('footerI3'),
      fetchNavItem('footerI4'),
    ]);

    setNavI1(navI1Data.data[0]);
    setNavI2(navI2Data.data[0]);
    setNavI3(navI3Data.data[0]);
    setNavI4(navI4Data.data[0]);
    setNavI5(navI5Data.data[0]);
    setFooterI1(footerI1Data.data[0]);
    setFooterI2(footerI2Data.data[0]);
    setFooterI3(footerI3Data.data[0]);
    setFooterI4(footerI4Data.data[0]);
  };

  useEffect(() => {
    fetchAllNavItems();
  }, []); 
  useEffect(() => {
    if (navI1 && navI2 && navI3 && navI4 && navI5) {
      const arr = [navI1, navI2, navI3, navI4, navI5];
     /*  console.log("Arr", JSON.stringify(arr, null, 2));
      console.log("Initial", JSON.stringify(intialNav, null, 2)); */
      
      const updatedNav = intialNav.map(navItem => {
        const matchingItem = arr.find(item => item.keyword === navItem.keyword);
        return {
          ...navItem,
          status: matchingItem ? matchingItem.status : navItem.status
        };
      });
  
      // console.log("Updated Initial Array:", JSON.stringify(updatedNav, null, 2));
      setIntialNav(updatedNav);  
    }
  }, [navI1, navI2, navI3, navI4, navI5]); 
  return (
    <div className="bg-mdark py-8 px-[12%] mt-auto ">
      <div className=" grid grid-cols-1 md:grid-cols-2 gap-10">
        <div className="">
          <img
            alt="bothut"
            src={footerI1?footerI1.image:""}
            className="w-48"
          />
          <p className="mt-5 w-[90%] mb-9 text-1xl text-mgray antialiased">
            {footerI1?footerI1.description:"loading..."}
          </p>

            <div className="flex mb-3">
              <span className="text-msky text-xl mr-3">
              <img
            alt="bothut"
            src={footerI2?footerI2.image:""}
            className="w-5 h-5"
          />
              </span>
              <p className="text-white  antialiased">{footerI2?footerI2.title:""}</p>
            </div>

            <div className="flex mb-3">
              <span className="text-msky text-xl mr-3">
              <img
            alt="bothut"
            src={footerI3?footerI3.image:""}
            className="w-5 h-5"
          />
              </span>
              <p className="text-white  antialiased">{footerI3?footerI3.title:""}</p>
            </div>
            <div className="flex mb-3">
              <span className="text-msky text-xl mr-3">
              <img
            alt="bothut"
            src={footerI4?footerI4.image:""}
            className="w-5 h-5"
          />
              </span>
              <p className="text-white  antialiased">{footerI4?footerI4.title:""}</p>
            </div>
        </div>

        <div className=" flex flex-col lg:flex-row gap-20 ">
          <div className="">
            <h1 className="text-white text-2xl">Quick Link</h1>
            <ul className="my-5">
              {intialNav.map((item) => (
                item.status?(
                <li
                  onClick={() => {
                    if (item.is_route) {
                      if (window.location.pathname === "/") {
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }
                      navigation(item.route);
                    } else if (item.is_route === false) {
                      if (item.id === 2) {
                        onAboutPress();
                      } else if (item.id === 3) {
                        onServicePress();
                      }
                    }
                  }}
                  className={`${
                    window.location.pathname === item.route
                      ? "text-msky"
                      : "text-white"
                  } cursor-pointer font-400 mb-4 text-1xl  ml-0 lg:ml-0  hover:text-msky`}
                  key={item.id}
                >
                  {item.name}
                </li>
                ):""
              ))}
            </ul>
          </div>
          <div className="flex flex-col gap-4">
            <h1 className="text-[20px] text-app_white">Useful Links</h1>
            <ul
              className={`flex shrink-0 flex-col gap-x-4 gap-y-3 font-poppins text-app_white`}
            >
              <li className="hover:text-app_primary cursor-pointer">
                <Link to={`/shop-main`}>Shop</Link>
              </li>
              <li className="cursor-pointer hover:text-app_primary">
                <Link to={"/privacy-policy"}>Privacy Policy</Link>
              </li>
              <li className="cursor-pointer hover:text-app_primary">
                <Link to={`/return-policy`}>Return Policy</Link>
              </li>
              <li className="cursor-pointer hover:text-app_primary">
                <Link to={`/shipping-policy`}>Shipping Policy</Link>
              </li>
              <li className="cursor-pointer hover:text-app_primary">
                <Link to={`/term-and-condition`}>Terms & Condition</Link>
              </li>
            </ul>
          </div>
          {/* <NewsLetter /> */}
        </div>
      </div>
      <hr className="my-6 border-mgray" />

      <p className="text-mgray text-center text-[16px]  antialiased">
        Bot Hut © 2025 All rights reserved.
      </p>
    </div>
  );
}

export default Footer;
