import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import NavBar from "../../component/NavBar/NavBar";
import NavbarShops from "../../component/navBarShop/navBar";
import Footer from "../../component/Footer/Footer";
import cross from "../../assets/svg/trash.svg";
import cart from "../../assets/svg/cart.svg";
import { Sheet, SheetContent, SheetTrigger } from "../../component/ui/sheet";
import hamburgerb from "../../assets/svg/hamburgerb.svg";
import AccordionCustom from "../../component/accordion/accordion";
import searchW from "../../assets/svg/mag-w.svg";
import logo from "../../assets/about.png";
// import { products } from "../../components/product";
// Redux
import { updateQuantity } from "../../cartSlice";
import { useDispatch, useSelector } from "react-redux";
import { removeFromCart } from "../../cartSlice";
// import { RootState } from "../../store";
//Redux end
const Cart = () => {
  const navigate = useNavigate();
  //Redux
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.items);
  const [searchTerm, setSearchTerm] = useState("");
  const [mobSearch, setMobSearch] = useState("");
  const handleNavigation = () => {
    navigate("/cart");
  };
  useEffect(() => {
    console.log("I am logged");
  }, [cartItems]);
  //redux end
  return (
    <div className="min-h-screen flex flex-col w-full ">
      <NavBar
        onAboutPress={() => navigate("/?section=aboutus")}
        onServicePress={() => navigate("/?section=service")}
      />
      {/* Nvbar section End */}
      {/* secondary Menu */}
      <div className="top-[70px] min-[400px]:top-[70px] min-[767px]:top-[73px] min-[768px]:top-[83px] min-[1440px]:top-[83px]  z-50 fixed w-full bg-white">
        <NavbarShops />
        <div className="flex gap-2 p-4 min-[1440px]:hidden items-center ">
          {/* mob menu start */}
          <Sheet>
            <SheetTrigger asChild>
              <button className="min-[1440px]:hidden inline-flex gap-2 items-center font-bold text-black text-[14px] font-Noto ">
                <img src={hamburgerb} className="h-5 w-5" alt="" />
                Menu
              </button>
            </SheetTrigger>
            <SheetContent
              side={"left"}
              className="no-scrollbar flex h-full w-full flex-col gap-3 overflow-scroll bg-gray-200 max-w-[300px] px-0"
            >
              <div className="flex flex-col gap-2 pt-10">
                <div className="px-4">
                  <div className="flex items-stretch gap-4 p-0 m-0 overflow-hidden rounded  bg-app_bg_gray border-none outline-none ">
                    <input
                      type="text"
                      className="w-full bg-transparent px-3 my-0 py-3 border-none outline-none focus:border-none"
                      placeholder="Search"
                      value={mobSearch}
                      onChange={(e) => setMobSearch(e.target.value)}
                    />
                    <button className="flex items-center self-stretch flex-col w-20 justify-center   bg-msky h-auto">
                      <img
                        src={searchW}
                        className="h-7 w-7"
                        alt=""
                        onClick={() => {
                          setSearchTerm(mobSearch);
                        }}
                      />
                    </button>
                  </div>
                </div>

                <div className="flex flex-col gap-4">
                  <AccordionCustom />
                </div>
              </div>
            </SheetContent>
          </Sheet>
          {/* Mobile Menu End */}
          {/* Cart on mobile screen start */}
          <button
            className="px-2  flex gap-1 cursor-pointer items-center"
            onClick={handleNavigation}
          >
            <img src={cart} className="w-4 h-4" alt="" />
            <span className="bg-app_primary text-app_white text-[12px] px-2">
              {cartItems.length}
            </span>
          </button>
          {/* Cart on mobile screen end */}
        </div>
      </div>
      <div className="bg-app_bg_gray bg-opacity-100 h-[90px] min-[400px]:h-[120px]  min-[1440px]:h-[150px]"></div>
      {/* secondary Menu ENd */}
      {/* Product start */}

      <div className="min-h-[500px] no-scrollbar bg-gray-50/70 flex-col justify-center items-center  overflow-y-scroll  relative z-0 mx-auto flex  w-[95%]    font-poppins md:w-[85%] p-10 mb-10">
        <table className="border hidden md:block">
          <thead className="border-b">
            <tr className="">
              <th
                scope="col"
                className="text-base  font-medium text-gray-900 px-6 py-4 text-left"
              >
                Action
              </th>
              <th
                scope="col"
                className="text-base font-medium text-gray-900 px-6 py-4 text-left"
              >
                Product
              </th>
              <th
                scope="col"
                className="text-base font-medium text-gray-900 px-6 py-4 text-left"
              >
                Price
              </th>
              <th
                scope="col"
                className="text-base font-medium text-gray-900 px-6 py-4 text-left"
              >
                Quantity
              </th>
              <th
                scope="col"
                className="text-base uppercase font-medium text-gray-900 px-6 py-4 text-left"
              >
                subtotal
              </th>
            </tr>
          </thead>
          <tbody>
            {cartItems.length > 0 ? (
              cartItems.map((item) => (
                <tr className="border-b" key={item.id}>
                  <td className="px-6 py-4 text-sm font-medium text-gray-900">
                    <button onClick={() => dispatch(removeFromCart(item.id))}>
                      <img src={cross} className="h-7 w-7" alt="delete" />
                    </button>
                  </td>
                  <td className="text-sm lg:items-center flex lg:flex-row flex-col gap-2 text-gray-900 px-6 py-4">
                    <img
                      src={item.imageUrls[0]} // Assuming the first image is the primary image
                      className="h-16 w-16"
                      alt={item.productName}
                    />
                    <span>{item.productName}</span>
                  </td>
                  <td className="text-sm whitespace-nowrap shrink-0 text-gray-900 font-light px-6 py-4">
                    {parseFloat(
                      item.productPrice.replace(/[^0-9.-]+/g, "")
                    ).toFixed(2)}{" "}
                    $
                  </td>
                  <td className="text-sm basis-full h-full min-h-full text-gray-900 font-light px-6">
                    <div className="flex">
                      <button
                        className="text-[18px] px-4 py-2 border"
                        onClick={() =>
                          dispatch(
                            updateQuantity({
                              id: item.id || item._id,
                              quantity:
                                item.quantity > 1 ? item.quantity - 1 : 1,
                            })
                          )
                        }
                      >
                        -
                      </button>
                      <span className="text-[18px] px-4 py-2 border">
                        {item.quantity}
                      </span>
                      <button
                        className="text-[18px] px-4 py-2 border"
                        onClick={() =>
                          dispatch(
                            updateQuantity({
                              id: item.id || item._id,
                              quantity: item.quantity + 1,
                            })
                          )
                        }
                      >
                        +
                      </button>
                    </div>
                  </td>
                  <td className="text-sm text-gray-900 font-light px-6 py-4">
                    {(
                      parseFloat(item.productPrice.replace(/[^0-9.-]+/g, "")) *
                      item.quantity
                    ).toFixed(2)}{" "}
                    $
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} className="text-center text-gray-500 py-4">
                  No items in the cart
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {/* <Link

to={"/checkout"}
className="py-3 text-center px-2 text-white bg-msky w-full mt-4 text-ap"
>
(Contact info@bothut.com for TAX and shipping charges)
</Link> */}
        {/* Small screen cart product start */}
        {cartItems.length > 0 ? (
          cartItems.map((item) => (
            <div
              className="bg-gray-100 rounded-lg shadow-lg w-full py-6 mt-4 px-3 block md:hidden"
              key={item.id}
            >
              <div className="flex justify-between w-full ">
                <div className="flex gap-3 w-full">
                  <img
                    src={item.imageUrls[0]}
                    alt={item.productName}
                    className="min-h-[100px] max-w-[100px]  aspect-square max-h-[100px] bg-mdark"
                  />
                  <div className="flex flex-col gap-2 w-full">
                    <div className="flex self-start w-full justify-between py-1 gap-3">
                      <div className="flex gap-3 items-center">
                        {/* <span className="font-medium">Price:</span> */}
                        <span className="font-medium">
                          {parseFloat(
                            item.productPrice.replace(/[^0-9.-]+/g, "")
                          ).toFixed(2)}{" "}
                          $
                        </span>
                      </div>
                      <button
                        className="text-red-500  hover:text-red-700"
                        onClick={() => dispatch(removeFromCart(item.id))}
                      >
                        {/* <span className="text-[12px]">Remove Item</span> */}
                        <img src={cross} className="h-7 w-7" alt="delete" />
                      </button>
                    </div>
                    <div className="flex items-center gap-4 mt-auto pb-1">
                      {/* <button className="font-semibold text-[18px]">QTY:</button> */}
                      <div className="flex">
                        <button
                          className=" px-3 py-2 border font-medium"
                          onClick={() =>
                            dispatch(
                              updateQuantity({
                                id: item.id || item._id,
                                quantity:
                                  item.quantity > 1 ? item.quantity - 1 : 1,
                              })
                            )
                          }
                        >
                          -
                        </button>
                        <span className=" px-3 py-2 border font-medium">
                          {item.quantity}
                        </span>
                        <button
                          className=" px-3 py-2 border font-medium"
                          onClick={() =>
                            dispatch(
                              updateQuantity({
                                id: item.id || item._id,
                                quantity: item.quantity + 1,
                              })
                            )
                          }
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="py-4">
                <p className="">{item.productName}</p>
                {/* <p className="text-gray-700">
                  Product Description Description Description Description
                  Description
                </p> */}
              </div>

              <div className="flex gap-1 items-center">
                <span className="font-medium">Sub Total:</span>
                <span className="">
                  {" "}
                  {(
                    parseFloat(item.productPrice.replace(/[^0-9.-]+/g, "")) *
                    item.quantity
                  ).toFixed(2)}{" "}
                  $
                </span>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center text-gray-500 py-4">No Item in cart</div>
        )}

        {/* Small screen cart product End */}

        <button
          disabled={cartItems.length < 1}
          onClick={() => {
            navigate("/checkout");
          }}
          className="disabled:cursor-not-allowed disabled:bg-opacity-80 py-3 text-center px-2 text-white bg-msky mt-4 "
        >
          (Contact info@bothut.com for TAX and shipping charges)
        </button>
        <button
          type="button"
          onClick={() => {
            navigate("/shop");
          }}
          className=" py-3 text-center px-2 flex justify-center items-center gap-2 text-msky hover:text-black w-full mt-4 "
        >
          <span className="text-2xl leading-[0px]">&#8592;</span>
          back to shop
        </button>
        {/* </div> */}
      </div>

      {/* Footer start */}
      <div className="mt-auto ">
        <Footer
          onAboutPress={() => navigate("/?section=aboutus")}
          onServicePress={() => navigate("/?section=service")}
        />
      </div>

      {/* Footer End */}
    </div>
  );
};
/////////////////////////////////////////////
export default Cart;
