import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import BrandPartner from "../../component/common/brandPartner";
import NavBar from "../../component/NavBar/NavBar";
import Footer from "../../component/Footer/Footer";
import ProductImageGallery from "../../component/ProductImageGallery/index"; // Import the image gallery
import spinner from "../../assets/svg/spinner.svg";
import favourite from "../../assets/svg/favH.svg";
import moodHeart from "../../assets/svg/moodHeart.svg";
import fav from "../../assets/svg/favH.svg";
import favr from "../../assets/svg/favF.svg";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../component/ui/tabs";
import SignUp from "../../component/signup/signUp";
import Ratings from "../../component/rating/rating";
import { Checkbox } from "../../component/ui/check-box";
import { SERVER_URL } from "../../constants/apiLinks";
import ScrollToTop from "../../component/scrollToTop/scroll";
import searchW from "../../assets/svg/mag-w.svg";
import AccordionCustom from "../../component/accordion/accordion";
import hamburgerb from "../../assets/svg/hamburgerb.svg";
import { Sheet, SheetContent, SheetTrigger } from "../../component/ui/sheet";
import NavbarShops from "../../component/navBarShop/navBar";
import cart from "../../assets/svg/cart.svg";
import { useDispatch, useSelector } from "react-redux";
import { updateQuantity } from "../../cartSlice";
import { addToCart } from "../../cartSlice";
import { addToWishlist, removeFromWishlist } from "../../wishlistSlice";
import { RootState } from "../../store";
import DOMPurify from "dompurify";
import DialogBox from "../../component/myOwnDialogBox/MyDialog";
function Product() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [product, setProduct] = useState<any>(null); // Initialize as null
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState<string>(""); // For search input
  const [mobSearch, setMobSearch] = useState<string>("");
  const [productName, setProductName] = useState<string>(''); // For search input
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const fetchProduct = async () => {
    try {
      const response = await fetch(`${SERVER_URL}/api/user/product/${id}`);
      const data = await response.json();
      setProduct(data.data);
      console.log(JSON.stringify(data.data, null, 2));
      console.log("DATA", data.data);
    } catch (error) {
      console.error("Error fetching product:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) fetchProduct();
  }, [id]);

  const handleNavigation = () => {
    navigate("/cart");
  };
  //Redux
  const dispatch = useDispatch();
  const cartItems = useSelector((state: RootState) => state.cart.items);
  const wishlist = useSelector((state: RootState) => state.wishlist.items);
  const handleCart = (product: any) => {
    if (cartItems[product.id]) {
    } else {
      dispatch(addToCart(product));
    }
    console.log(cartItems);
    console.log(cartItems.length);
  };

  const getQuantityById = (id: string) => {
    const item = cartItems.find((cartItem) => cartItem.id === id);
    return item ? item.quantity : 0; // Returns 0 if no matching item is found
  };

  // Handle Add to Wishlist
  const handleWishlist = (product: any) => {
    console.log("Wishlist Items:", wishlist);

    const isInWishlist = wishlist.some((item) => item.id === product.id);

    if (isInWishlist) {
      dispatch(removeFromWishlist(product));
      // toast.info("Product removed from wishlist");
    } else {
      dispatch(addToWishlist(product));
      // toast.success("Product added to wishlist");
    }

    // console.log("Wishlist Items:", JSON.stringify(wishlist,null,2));
  };
  useEffect(() => {}, [cartItems]);
  //redux end

  /* const renderPrice = (priceText:any) => {
    const pricePattern = /(\$\d+\.\d+)\s+(\$\d+\.\d+)\s+\((.+?)\)/;
    const match = priceText.match(pricePattern);
  
    if (!match) {
      return<h1 className="text-[20px] text-app_primary sm:text-[24px] md:text-[36px]">
      {priceText}</h1>; 
    }
  
    const [, originalPrice, salePrice, unit] = match;
  
    return (
      <h1 className="text-[20px] text-app_primary sm:text-[24px] md:text-[36px]">
        <span className="line-through text-sm text-gray-500 mr-2">{originalPrice}</span>
        <span className=" mr-2">{salePrice}</span>
        <span className="">({unit})</span>
      </h1>
    );
  } */
  const renderPrice = (priceText: any) => {
    // Match prices with commas and optional decimals
    const pricePattern =
      /^\$(\d{1,3}(,\d{3})*(\.\d{2})?)\s*(\$(\d{1,3}(,\d{3})*(\.\d{2})?))?\s*(\((.+?)\))?$/;
    const match = priceText.match(pricePattern);

    if (!match) {
      return (
        <h1 className="text-[20px] text-app_primary sm:text-[24px] md:text-[36px]">
          ${priceText}
        </h1>
      ); // Handle invalid format
    }

    const originalPrice = match[1]; // First price
    const salePrice = match[5]; // Sale price (optional)

    return (
      <h1 className="text-[20px] text-app_primary sm:text-[24px] md:text-[36px]">
        {salePrice ? (
          <>
            <span className="line-through text-sm text-gray-500 mr-2">
              ${originalPrice}
            </span>
            <span className="mr-2">${salePrice}</span>
          </>
        ) : (
          <span className="mr-2">${originalPrice}</span>
        )}
      </h1>
    );
  };

  const renderShortDescription = (description: string) => {
    const descriptionItems = description.split("\n\n"); // Split on double newlines
    return (
      <ul className="flex gap-3 text-app_text text-[14px] flex-col mt-2 list-disc ml-4">
        {descriptionItems.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    );
  };

  /*   const renderFullDescription = (description: string) => {
    // Regex patterns to match sections and key-value pairs
    const sectionRegex = /(?:^|\n\n)([^\n]+):/g;
    const keyValueRegex = /([^:]+):([^:\n]+)/g;
  
    // Split the description into sections using regex
    const sections:string[] = [];
    let match;
    while ((match = sectionRegex.exec(description)) !== null) {
      sections.push(match[1].trim());
    }
  
    // Generate the structured HTML
    return (
      <div className="text-app_text text-[14px] mt-2">
        {sections.map((section:any, index:any) => {
          // Find the content for each section
          const sectionStart = description.indexOf(section);
          const nextSectionStart = description.indexOf(
            sections[index + 1] || "",
            sectionStart + section.length
          );
          const sectionContent = description.substring(
            sectionStart + section.length,
            nextSectionStart === -1 ? undefined : nextSectionStart
          );
  
          // Extract key-value pairs from the section content
          const keyValuePairs = [];
          let kvMatch;
          while ((kvMatch = keyValueRegex.exec(sectionContent)) !== null) {
            keyValuePairs.push({
              key: kvMatch[1].trim(),
              value: kvMatch[2].trim(),
            });
          }
  
          return (
            <div key={index} className="mb-4">
              <h3 className="font-bold">{section}</h3>
              <ul className="list-disc ml-6">
                {keyValuePairs.map((pair, i) => (
                  <li key={i}>
                    <strong>{pair.key}:</strong> {pair.value}
                  </li>
                ))}
              </ul>
            </div>
          );
        })}
      </div>
    );
  }; */
  const renderFullDescription = (description: string) => {
    // Remove "Description" from the start if it exists
    if (description.startsWith("Description")) {
      description = description.substring("Description".length).trim();
    }

    // Regex patterns to handle specific concatenations
    const capitalizedWordSplitRegex = /([a-z])([A-Z])/g; // Split lowercase followed by uppercase
    const numberCapitalSplitRegex = /(\d+)([A-Z])/g; // Split digits followed by uppercase
    const specialCharCapitalSplitRegex = /([^a-zA-Z0-9\s:/-])([A-Z])/g; // Exclude '-', ':', and '/'

    // Fix concatenated patterns
    let fixedDescription = description
      .replace(capitalizedWordSplitRegex, "$1\n$2") // Split lowercase-uppercase
      .replace(numberCapitalSplitRegex, "$1\n$2") // Split digits-uppercase
      .replace(specialCharCapitalSplitRegex, "$1\n$2"); // Split special-uppercase, excluding '-', ':', and '/'

    // Bold "Product Information"
    const boldProductInfoRegex = /\nProduct Information\n\n/g;
    fixedDescription = fixedDescription.replace(
      boldProductInfoRegex,
      "\n<strong>Product Information</strong>\n"
    );

    // Bold "Features"
    const boldFeaturesRegex = /Features:\n\n/g;
    fixedDescription = fixedDescription.replace(
      boldFeaturesRegex,
      "<strong>Features:</strong>\n"
    );

    // Bold "Miscellaneous" without removing the starting \n and removing only one \n from the end
    const boldMiscellaneousRegex = /\nMiscellaneous\n\n/g;
    fixedDescription = fixedDescription.replace(
      boldMiscellaneousRegex,
      "\n<strong>Miscellaneous</strong>\n"
    );

    // New pattern: Remove \n\n from the start and make the word bold
    const boldStartPatternRegex = /\n\n\n([^\n]+)\n\n/g;
    fixedDescription = fixedDescription.replace(
      boldStartPatternRegex,
      "\n<strong>$1</strong>\n"
    );

    // Bold "Marketing Information:" and "Product Material:"
    const boldMarketingInfoRegex = /Marketing Information:/g;
    const boldProductMaterialRegex = /Product Material:/g;
    fixedDescription = fixedDescription.replace(
      boldMarketingInfoRegex,
      "<strong>Marketing Information:</strong>"
    );
    fixedDescription = fixedDescription.replace(
      boldProductMaterialRegex,
      "<strong>Product Material:</strong>"
    );

    // Remove one \n for "Material:\n\n"
    const materialDoubleNewlineRegex = /Material:\n\n/g;
    fixedDescription = fixedDescription.replace(
      materialDoubleNewlineRegex,
      "Material:\n"
    );

    // Ensure "Manufacturer Part Number" starts from a new line
    const manufacturerPartNumberRegex = /([^.\n])Manufacturer Part Number/g;
    fixedDescription = fixedDescription.replace(
      manufacturerPartNumberRegex,
      "$1\nManufacturer Part Number"
    );

    // Ensure "Product Name" starts from a new line
    const productNameRegex = /([^.\n])Product Name/g;
    fixedDescription = fixedDescription.replace(
      productNameRegex,
      "$1\nProduct Name"
    );
    // Ensure "Product Name" starts from a new line
    /*  const marketingInformationRegex = /([^.\n])Marketing Information/g;
    fixedDescription = fixedDescription.replace(
      marketingInformationRegex,
      "$1\nMarketing Information"
    ); */

    // Remove one \n for `:\n\n`
    const colonDoubleNewlineRegex = /:\n\n/g;
    fixedDescription = fixedDescription.replace(colonDoubleNewlineRegex, ":\n");

    // Split the description into sections using regex
    const sectionRegex = /(?:^|\n\n)([^\n]+)\n\n/g; // Matches section headers
    const sections: string[] = [];
    let sectionMatch;
    while ((sectionMatch = sectionRegex.exec(fixedDescription)) !== null) {
      sections.push(sectionMatch[1].trim());
    }

    // Add an end marker to capture the last section correctly
    sections.push("END_MARKER");

    // Generate the structured HTML
    return (
      <div className="text-app_text text-[14px] mt-2">
        {sections.slice(0, -1).map((section: string, index: number) => {
          // Find the content for each section
          const sectionStart = fixedDescription.indexOf(section);
          const nextSectionStart = fixedDescription.indexOf(
            sections[index + 1],
            sectionStart + section.length
          );
          const sectionContent = fixedDescription
            .substring(
              sectionStart + section.length,
              nextSectionStart === -1 ? undefined : nextSectionStart
            )
            .trim();

          return (
            <div key={index} className="mb-4">
              {/* Section title */}
              <h3 className={index === 0 ? "font-bold" : ""}>{section}</h3>
              {index === 0 && <hr className="my-2" />}{" "}
              {/* Add <hr> after General Information */}
              <p
                className="whitespace-pre-wrap"
                dangerouslySetInnerHTML={{ __html: sectionContent }}
              ></p>
            </div>
          );
        })}
      </div>
    );
  };

  function convertClassToClassName(htmlString: string) {
    if (!htmlString || typeof htmlString !== "string") {
      throw new Error("Invalid input: Provide a valid HTML string.");
    }

    // Check and replace only if the 'class' attribute exists
    return htmlString.replace(/\bclass="/g, 'className="');
  }

  const sanitizedHTML = (text: string) => {
    const convertedHtml = convertClassToClassName(text);
    return DOMPurify.sanitize(convertedHtml);
  };

  if (loading)
    return (
      <div className="flex flex-col h-[50vh] items-center justify-center">
        <ScrollToTop />
        <img src={spinner} className="h-24 w-44" alt="Loading" />
      </div>
    ); // Handle loading state
  if (!product) return <p>Product not found.</p>; // Handle product absence

  // Prepare images for the gallery
  const galleryImages = product.imageUrls?.map((url: string) => ({
    url, // Use the same URL for both full-size and thumbnail in this example
    thumbnailUrl: url, // Adjust this if you have separate thumbnails
  }));

  return (
    <>
      <style>
        {`
          .parent-container {
            color:#4a4a4a;
            min-width:100%;
            font-family: Noto Sans;

          }
          .parent-container b {
            font-weight: 600;
            color:#4a4a4a;
            font-family: Noto Sans, sans-serif;

          }

        `}
      </style>
      <NavBar
        onAboutPress={() => navigate("/?section=aboutus")}
        onServicePress={() => navigate("/?section=service")}
      />
      {/* secondary Menu */}
      <div className="top-[70px] min-[400px]:top-[70px] min-[767px]:top-[73px] min-[768px]:top-[83px] min-[1440px]:top-[83px]  z-50 fixed w-full bg-white">
        <NavbarShops />
        <div className="flex gap-2 p-4 min-[1440px]:hidden items-center ">
          {/* mob menu start */}
          <Sheet>
            <SheetTrigger asChild>
              <button className="min-[1440px]:hidden inline-flex gap-2 items-center font-bold text-black text-[14px] font-Noto ">
                <img src={hamburgerb} className="h-5 w-5" alt="" />
                Menu
              </button>
            </SheetTrigger>
            <SheetContent
              side={"left"}
              className="no-scrollbar flex h-full w-full flex-col gap-3 overflow-scroll bg-gray-200 max-w-[300px] px-0"
            >
              <div className="flex flex-col gap-2 pt-10">
                <div className="px-4">
                  <div className="flex items-stretch gap-4 p-0 m-0 overflow-hidden rounded  bg-app_bg_gray border-none outline-none ">
                    <input
                      type="text"
                      className="w-full bg-transparent px-3 my-0 py-3 border-none outline-none focus:border-none"
                      placeholder="Search"
                      value={mobSearch}
                      onChange={(e) => setMobSearch(e.target.value)}
                    />
                    <button className="flex items-center self-stretch flex-col w-20 justify-center   bg-msky h-auto">
                      <img
                        src={searchW}
                        className="h-7 w-7"
                        alt=""
                        onClick={() => {
                          setSearchTerm(mobSearch);
                        }}
                      />
                    </button>
                  </div>
                </div>

                <div className="flex flex-col gap-4">
                  <AccordionCustom />
                </div>
              </div>
            </SheetContent>
          </Sheet>
          {/* Mobile Menu End */}
          {/* Cart on mobile screen start */}
          <button
            className="px-2  flex gap-1 cursor-pointer items-center"
            onClick={handleNavigation}
          >
            <img src={cart} className="w-4 h-4" alt="" />
            <span className="bg-app_primary text-app_white text-[12px] px-2">
              {cartItems.length}
            </span>
          </button>
          {/* Cart on mobile screen end */}
        </div>
      </div>
      <div className="bg-app_bg_gray bg-opacity-100 h-[90px] min-[400px]:h-[120px]  min-[1440px]:h-[150px]"></div>

      {/* secondary Menu ENd */}
      <section className="flex flex-col h-full justify-center z-10 md:w-[85%] w-[95%] mx-auto font-poppins px-4 pt-20 relative">
        <div className="flex max-w-screen-xl mx-auto flex-col gap-2 md:flex-row">
          {/* <div className="max-w-[295px] self-center md:self-start lg:self-stretch lg:w-full md:flex-[4] md:px-4 bg-red-500"> */}
          <div className="max-w-[295px] sm:max-w-[400px] md:max-w-[300px] md:w-auto self-center md:self-start lg:self-stretch lg:max-w-[700px]  lg:w-[512px] md:flex-[4] md:px-4 ">
            {/* Replace MagnifierImage with ProductImageGallery */}
            {galleryImages && <ProductImageGallery images={galleryImages} />}
          </div>
          <div className="md:flex-[7] px-4">
            <h2 className="text-xl sm:text-2xl font-semibold font-Noto dark:text-white mb-2">
              {product.productName}
            </h2>
            <div className="bg-gray-300 h-[1px] w-full my-4"></div>
            <h1 className="">
              {product.productPrice && renderPrice(product.productPrice)}
            </h1>

            {/*  <div className="mb-4 font-Noto">
              {product.shortDescription &&
                renderShortDescription(product.shortDescription)}
            </div> */}
            <div className="mb-4 font-Noto">
              {product.shortDescriptionHtml && (
                <div
                  className="parent-container prose-sm prose-p:text-[#4a4a4a] prose-hr:mt-3 prose-hr:mb-4 prose-li:text-[#4a4a4a] prose-h2:font-normal prose-h2:text-lg prose-h2:font-Noto prose-headings:font-medium prose- prose-li:list-disc"
                  dangerouslySetInnerHTML={{
                    __html: sanitizedHTML(product.shortDescriptionHtml),
                  }}
                />
              )}
            </div>
            <div className="bg-gray-300 h-[1px] w-full my-10"></div>

            <div className="flex gap-9">
              <div className="flex border items-center">
                <button
                  onClick={() =>
                    dispatch(
                      updateQuantity({
                        id: product.id,
                        quantity:
                          Number(getQuantityById(id as string)) > 1
                            ? Number(getQuantityById(id as string)) - 1
                            : 1,
                      })
                    )
                  }
                  className="px-4 py-2"
                >
                  -
                </button>

                <button className="px-5 py-2 border-x">
                  {getQuantityById(id as string)}
                </button>

                <button
                  onClick={() =>
                    dispatch(
                      updateQuantity({
                        id: product.id,
                        quantity: Number(getQuantityById(id as string)) + 1,
                      })
                    )
                  }
                  className="px-4 py-2"
                >
                  +
                </button>
              </div>

              <div
                className={`${
                  cartItems.find((cartItem) => cartItem.id == id)
                    ? "!bg-black"
                    : "bg-app_primary"
                }  w-full cursor-pointer bg-app_primary text-app_white group-hover:block`}
              >
                <div className="flex justify-center">
                  {cartItems.find((cartItem) => cartItem.id == id) ? (
                    <Link to="/cart" className="h-full w-full py-3 text-center">
                      View Cart
                    </Link>
                  ) : (
                    <span
                      className="h-full w-full py-3 text-center"
                      onClick={() => handleCart(product)}
                    >
                      Add to Cart
                    </span>
                  )}
                </div>
              </div>
            </div>
            {/*             <img
              src={favourite}
              className="w-5 h-5 my-4"
              alt="favourite icon"
            /> */}
            <button className="" onClick={() => {handleWishlist(product);setIsDialogOpen(true);setProductName(product.productName);}}
>
            {/* <div className="absolute right-1 top-1"> */}
              <img
                // src={ wishlist.some((item) => item.id === item.id) ? favr : fav} // Show different icons for added/removed
                /* src={wishlist.some(
                                    (itemStored) => itemStored.id === item.id) ? favr : fav} // Show different icons for added/removed */
                src={
                  wishlist.some((itemStored) => itemStored.id === id)
                    ? favr
                    : fav
                } // Show different icons for added/removed
                className="w-5 h-5 my-4"
                alt={
                  wishlist.some((item) => item.id === id)
                    ? "Remove from wishlist"
                    : "Add to wishlist"
                }
              />
            </button>
            {/* </div> */}
            <div className="bg-gray-300 h-[1px] w-full mb-4 mt-8"></div>
            <p className="">{product?.brand}</p>
          </div>
        </div>
      </section>

      <Tabs defaultValue="Description" className=" md:w-full mt-10">
        <TabsList className="flex overflow-x-auto md:overflow-hidden gap-4 m-0 p-0 bg-white border-y py-2">
          {product.fullDescriptionHtml ? (
            <TabsTrigger value="Description" className="ml-52 md:ml-0">
              Description
            </TabsTrigger>
          ) : (
            ""
          )}
          {product.additionalInfo ? (
            <TabsTrigger value="addInfo">Additional information</TabsTrigger>
          ) : (
            ""
          )}
          {product.brand && product.brand !== "N/A" ? (
            <TabsTrigger value="brand">Brand</TabsTrigger>
          ) : (
            ""
          )}
          <TabsTrigger value="Review">Review (0)</TabsTrigger>
        </TabsList>

        <TabsContent value="Description">
          <div className="flex flex-col gap-3 px-8  max-w-screen-xl mx-auto">
            {/* <span className="text-[18px]">Description</span> */}
            {/* <div className="mt-6">
              {product.fullDescription &&
                renderFullDescription(product.fullDescription)}
            </div> */}
            <div className="mt-2  px-2">
              {product.fullDescriptionHtml && (
                <div
                  className="parent-container prose prose-sm prose-p:text-[#4a4a4a] prose-hr:mt-3 prose-hr:mb-4 prose-li:text-[#4a4a4a] prose-h2:font-normal prose-h2:text-lg prose-h2:font-Noto prose-headings:font-medium prose-li:list-disc prose-a:no-underline  prose-a:font-normal prose-table:w-full prose-table:table-auto prose-thead:text-sm prose-tr:text-sm"
                  dangerouslySetInnerHTML={{
                    __html: sanitizedHTML(product.fullDescriptionHtml),
                  }}
                />
              )}
            </div>
          </div>
        </TabsContent>

        <TabsContent value="addInfo">
          <div className="flex flex-col gap-3 px-8 max-w-screen-xl mx-auto">
            <span className="text-[18px] font-Noto font-normal pt-2 ">
              Additional information
            </span>
            <div className="mt-2 grid grid-cols-2 grid-rows-2 ">
              <p className="font-semibold text-[#4a4a4a] text-sm border border-l-0 px-4 py-2 border-b-0">
                Weight
              </p>
              <p className="border border-r-0 border-b-0 px-4 py-2 text-[#4a4a4a] text-sm">
                {product.additionalInfo && product.additionalInfo.Weight}
              </p>
              <p className="font-semibold border border-l-0 px-4 py-2 text-[#4a4a4a] text-sm">
                Dimensions
              </p>
              <p className="border border-r-0 px-4 py-2 text-[#4a4a4a] text-sm">
                {product.additionalInfo && product.additionalInfo.Dimensions}
              </p>
            </div>
          </div>
        </TabsContent>

        <TabsContent value="brand">
          <div className="flex flex-col gap-2 px-8 max-w-screen-xl mx-auto">
            <span className="pt-2 text-[18px] font-family: Noto Sans, sans-serif; ">
              Brand
            </span>
            <div className=" ">
              <p className="font-semibold text-[#4a4a4a]">{product.brand}</p>
            </div>
          </div>
        </TabsContent>

        <TabsContent value="Review">
          <div className="flex flex-col gap-2 px-8 max-w-screen-xl mx-auto">
            <h1 className="pt-2 text-[18px] ">Reviews</h1>
            <span className="text-[#4a4a4a] ">No reviews yet.</span>
          </div>
        </TabsContent>
      </Tabs>
{/* //////////////////MODAL /////////////////// */}
<DialogBox isOpen={isDialogOpen}
    onClose={() => setIsDialogOpen(false)}
    back={true}
    >
      <div className="h-[20vh] flex flex-col justify-center items-center " >
      <div className="flex flex-col items-center  font-Noto">
<img src={moodHeart} alt="icon" className="w-12 h-12 mb-1"/>
      <p className="text-sm ">Status Updated In Wish List</p>
      <span className="text-sm">for</span>
      <p className="text-sm text-center">{productName}</p>
      <div className="flex flex-col w-full gap-2 pt-4 pb-10">
      <button type="button"  onClick={() => {
            navigate("/wishlist");
          }} className="px-4 py-1 rounded-sm bg-app_primary text-white hover:bg-msky">view wish list</button>
      <button type="button" onClick={()=>setIsDialogOpen(false)} className="px-4 py-1 rounded-sm bg-app_primary text-white hover:bg-msky">close</button>
      </div>
      </div>  
      </div>
</DialogBox>     
{/* //////////////////MODAL END /////////////////// */}
      <BrandPartner />
      <SignUp />
      <Footer
        onAboutPress={() => navigate("/?section=aboutus")}
        onServicePress={() => navigate("/?section=service")}
      />
    </>
  );
}

export default Product;
