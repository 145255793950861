import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import cart from "../../assets/svg/cart.svg";
import {
  NavigationMenu,
  NavigationMenuLink,
  NavigationMenuList,
} from "../../component/ui/navigation-menu";
import { cn } from "../../lib/utils";
import BreakroomMenu from "./breakroom";
import searchB from "../../assets/svg/mag.svg";
import cancel from "../../assets/svg/cancel.svg";
import { SERVER_URL } from "../../constants/apiLinks";
import spinner from "../../assets/svg/spinner.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";

interface Category {
  id: string;
  name: string;
  slug:string;
  status: number;
  children?: Category[];
}
function NavbarShops() {
  const navigate = useNavigate();
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState<any>();
  const [loading,setLoading] = useState(false);
  const [categories, setCategories] = useState<Category[]>([]);
  const [trimKeyword,setTrimKeyword] = useState<any>();
  const cartItems = useSelector((state: RootState) => state.cart.items);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${SERVER_URL}/api/findallcategory?name=${searchQuery}`);
        if (!response.ok) throw new Error('Failed to fetch categories');

        const data = await response.json();
        setCategories(data.data);
        // console.log("DATA",JSON.stringify(data,null,2));
        
      } catch (err: any) {
        console.log(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, [searchQuery]);

  async function fetchProducts() {
    try{
      setLoading(true)
      const response = await fetch(`${SERVER_URL}/api/user/product?search=${searchQuery}&limit=10`);
      const data = await response.json();
      // console.log("PR",data);
      
      setSearchResults(data.data);
    }
    catch(error){
  console.log("ERROR",error);
  
    }
    finally{
      setLoading(false)
    }
    
  }     
  
    useEffect(() => {
    
      fetchProducts();
    }, [searchQuery]);

  const handleNavigation = () => {
    navigate("/cart");
  };

  const toggleSearch = () => {
    setIsSearchExpanded((prev) => !prev);
    setSearchQuery("");
    setSearchResults([]);
  };

  function getFirstWord(input:string) {
    if (!input) return ""; // Handle empty or undefined input
    return input.split(" ")[0];
  }
  return (
    <>
      <div className="  flex w-[95%] flex-col  gap-3 px-4  lg:flex-row justify-center relative">
        <NavigationMenu>
          <NavigationMenuList className="hidden relative items-center py-2 min-[1440px]:flex z-[999999999]">
            {/* BreakRoom Start */}
            <BreakroomMenu />
            {/* BreakRoom End */}
            {/* Cart Button */}
            <button
              className="px-4 flex gap-1 cursor-pointer items-center"
              onClick={handleNavigation}
            >
              <img src={cart} className="w-4 h-4" alt="" />
              <span className="bg-app_primary text-app_white text-[12px] px-2">
                {cartItems.length}
              </span>
            </button>
            {/* cart btn end */}
            {/* Expandable Search Button */}
            <div className=" flex items-center">
              {!isSearchExpanded && (
                <button
                  onClick={toggleSearch}
                  className="px-3 py-2 text-sm font-medium hover:bg-gray-100 rounded-md"
                >
                <img src={searchB} className="w-4 h-4" alt="" />
                </button>
              )}
              {isSearchExpanded && (
                <div className="z-10 absolute left-0 flex w-full items-center transition-all duration-300">
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search..."
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-app_primary bg-white"
                  />
                  <button
                    onClick={toggleSearch}
                    className=" ml-[1px] px-3 py-2 text-sm font-medium bg-white hover:bg-gray-100"
                  >
                    <img src={cancel} className="w-4 h-4" alt="" />
                  </button>
                </div>
              )}
            </div>
            {/* Expandable Search End */}
  {/* Search Results */}
  {isSearchExpanded  && searchQuery.length>3 && (
    <div className="p-4 min-h-52 max-h-[70vh] overflow-y-scroll absolute top-full left-0 w-full  bg-white z-50 border border-gray-200 rounded-md mt-2 shadow-lg">
             {loading?(
              <div className="flex flex-col h-[50vh] items-center justify-center">
            <img src={spinner} className="h-24 w-44" alt="Favorited" />
            </div>
             ):(
             <div>
{categories.length<1?(
""
):(
              <ul className="">
              <p className="text-sm border-b px-4 uppercase">Categories</p>
              {categories.map((result:any) => (
                  <li
                    key={result.id}
                    className="flex items-center gap-4 px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => navigate(`/product-category/${result.slug}`)}
                  >
                   <p className=""> {result.name}</p>
                  </li>
                ))} 
              </ul>
              )}
<p className="text-sm border-b px-4 uppercase">Products</p>
{searchResults.length<1?(
            <h1 className="text-lg text-center mt-5">No result found Try different keyword</h1>
):(
              <ul className="">
              {searchResults.map((result:any) => (
                  <li
                    key={result.id}
                    className="flex items-center gap-4 px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => navigate(`/product/${result.id}`)}
                  >
                   <img src={result.imageUrls[0]} className="w-10 h-10 border"></img>
                   <p className=""> {result.productName}</p>
                  </li>
                ))} 
              </ul>
              )}
              <p onClick={() => navigate(`/product-category/${getFirstWord(searchQuery)}`)} className="hover:bg-gray-100 py-2 cursor-pointer text-sm text-center">
              view all products
              </p>
             </div>
             )}
             
    </div>
            )}
            {/* Expandable Search End */}

          </NavigationMenuList>
        </NavigationMenu>
      </div>
    </>
  );
}

export default NavbarShops;

const ListItem = React.forwardRef<
  React.ElementRef<"a">,
  React.ComponentPropsWithoutRef<"a">
>(({ className, title, children, ...props }, ref) => {
  return (
    <li>
      <NavigationMenuLink asChild>
        <a
          ref={ref}
          className={cn(
            "hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors only:mb-10",
            className
          )}
          {...props}
        >
          <div className="text-sm font-medium leading-none">{title}</div>
          <p className="text-muted-foreground line-clamp-2 text-sm leading-snug">
            {children}
          </p>
        </a>
      </NavigationMenuLink>
    </li>
  );
});
ListItem.displayName = "ListItem";
