import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addToWishlist, removeFromWishlist } from "../../wishlistSlice";
import NavBar from "../../component/NavBar/NavBar";
import NavbarShops from "../../component/navBarShop/navBar";
import Footer from "../../component/Footer/Footer";
import cancel from "../../assets/svg/cancel.svg";
import cancelw from "../../assets/svg/canw.svg";
import inStock from "../../assets/svg/check.svg";
import { Sheet, SheetContent, SheetTrigger } from "../../component/ui/sheet";
import searchW from "../../assets/svg/mag-w.svg";
import AccordionCustom from "../../component/accordion/accordion";
import hamburgerb from "../../assets/svg/hamburgerb.svg";
import cart from "../../assets/svg/cart.svg";
import { addToCart } from "../../cartSlice";

const WhishList = () => {
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate("/cart");
  };
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.items);
  const wishlist = useSelector((state) => state.wishlist.items); // Access Redux state
  const [isHovered, setIsHovered] = useState(false);
  const [searchTerm, setSearchTerm] = useState(); // For search input
  const [mobSearch, setMobSearch] = useState();
  const handleRemove = (product) => {
    dispatch(removeFromWishlist(product)); // Remove item from wishlist
  };
  const handleCart = (product) => {
    if (cartItems[product.id]) {
    } else {
      dispatch(addToCart(product));
    }
    console.log(cartItems);
    console.log(cartItems.length);
  };
  const renderPrice = (priceText) => {
    // Match prices with commas and optional decimals
    const pricePattern =
      /^\$(\d{1,3}(,\d{3})*(\.\d{2})?)\s*(\$(\d{1,3}(,\d{3})*(\.\d{2})?))?\s*(\((.+?)\))?$/;
    const match = priceText.match(pricePattern);

    if (!match) {
      return <h1 className=" text-app_primary ">${priceText}</h1>; // Handle invalid format
    }

    const originalPrice = match[1]; // First price
    const salePrice = match[5]; // Sale price (optional)

    return (
      <h1 className=" text-app_primary ">
        {salePrice ? (
          <>
            <span className="line-through text-sm text-gray-500 mr-2">
              ${originalPrice}
            </span>
            <span className="mr-2">${salePrice}</span>
          </>
        ) : (
          <span className="mr-2">${originalPrice}</span>
        )}
      </h1>
    );
  };
  return (
    <div className="min-h-screen flex flex-col w-full font-Noto text-sm">
      <NavBar
        onAboutPress={() => navigate("/?section=aboutus")}
        onServicePress={() => navigate("/?section=service")}
      />

      {/* secondary Menu */}
      <div className="top-[70px] min-[400px]:top-[70px] min-[767px]:top-[73px] min-[768px]:top-[83px] min-[1440px]:top-[83px]  z-50 fixed w-full bg-white">
        <NavbarShops />
        <div className="flex gap-2 p-4 min-[1440px]:hidden items-center ">
          {/* mob menu start */}
          <Sheet>
            <SheetTrigger asChild>
              <button className="min-[1440px]:hidden inline-flex gap-2 items-center font-bold text-black text-[14px] font-Noto ">
                <img src={hamburgerb} className="h-5 w-5" alt="" />
                Menu
              </button>
            </SheetTrigger>
            <SheetContent
              side={"left"}
              className="no-scrollbar flex h-full w-full flex-col gap-3 overflow-scroll bg-gray-200 max-w-[300px] px-0"
            >
              <div className="flex flex-col gap-2 pt-10">
                <div className="px-4">
                  <div className="flex items-stretch gap-4 p-0 m-0 overflow-hidden rounded  bg-app_bg_gray border-none outline-none ">
                    <input
                      type="text"
                      className="w-full bg-transparent px-3 my-0 py-3 border-none outline-none focus:border-none"
                      placeholder="Search"
                      value={mobSearch}
                      onChange={(e) => setMobSearch(e.target.value)}
                    />
                    <button className="flex items-center self-stretch flex-col w-20 justify-center   bg-msky h-auto">
                      <img
                        src={searchW}
                        className="h-7 w-7"
                        alt=""
                        onClick={() => {
                          setSearchTerm(mobSearch);
                        }}
                      />
                    </button>
                  </div>
                </div>

                <div className="flex flex-col gap-4">
                  <AccordionCustom />
                </div>
              </div>
            </SheetContent>
          </Sheet>
          {/* Mobile Menu End */}
          {/* Cart on mobile screen start */}
          <button
            className="px-2  flex gap-1 cursor-pointer items-center"
            onClick={handleNavigation}
          >
            <img src={cart} className="w-4 h-4" alt="" />
            <span className="bg-app_primary text-app_white text-[12px] px-2">
              {cartItems.length}
            </span>
          </button>
          {/* Cart on mobile screen end */}
        </div>
      </div>
      <div className="bg-app_bg_gray bg-opacity-100 h-[90px] min-[400px]:h-[120px]  min-[1440px]:h-[150px]"></div>
      {/* secondary Menu ENd */}

      {/* Wishlist Items */}
      <div className="min-h-[500px] flex-col justify-center items-center mx-auto w-[95%] md:w-[85%] p-10 mb-10">
        {wishlist.length > 0 ? (
          <div className="hidden md:block">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="">
                <tr>
                  <th className=" py-2">Product Name</th>
                  <th className="px-3 py-2 min-w-[80px]">Price</th>
                  <th className="px-3  py-2 min-w-[120px]">Stock Status</th>
                  <th className="px-3 py-2">Actions</th>
                </tr>
              </thead>
              <tbody className="text-center ">
                {wishlist.map((product) => (
                  <tr key={product.id} className="">
                    <td className="flex items-center gap-4  py-2">
                      <button
                        className="bg-[#eeeeee] shrink-0 p-1 hover:bg-app_primary rounded-full"
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        onClick={() => handleRemove(product)}
                      >
                        <img
                          src={isHovered ? cancelw : cancel}
                          className="w-4 h-4 shrink-0"
                          alt="Remove"
                        />
                      </button>
                      <img
                        src={product.imageUrls[0] || ""}
                        alt={product.productName}
                        className="w-16 h-16 lg:w-20 lg:h-20"
                      />
                      <span>{product.productName}</span>
                    </td>
                    <td>{renderPrice(product.productPrice)}</td>
                    <td>
                      <div className="flex gap-1 justify-center items-center">
                        <img src={inStock} alt="In stock" className="w-4 h-4 lg:h-5 lg:w-5 " />
                        <span>In stock</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className={`${
                          cartItems.find(
                            (cartItem) => cartItem.id == product.id
                          )
                            ? "!bg-black"
                            : "bg-app_primary"
                        }   cursor-pointer bg-app_primary text-app_white group-hover:block`}
                      >
                        <div className="flex justify-center">
                          {cartItems.find(
                            (cartItem) => cartItem.id == product.id
                          ) ? (
                            <Link to="/cart" className="h-full p-2 text-center">
                              View Cart
                            </Link>
                          ) : (
                            <span
                              className="h-full  p-2 text-center"
                              onClick={() => handleCart(product)}
                            >
                              Add to Cart
                            </span>
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button
              type="button"
              onClick={() => {
                navigate("/shop");
              }}
              className=" py-3 text-center px-2 flex justify-center items-center gap-2 text-msky hover:text-black w-full mt-4 "
            >
              <span className="text-2xl leading-[0px] ">&#8592;</span>
              back to shop
            </button>
          </div>
        ) : (
          <div className="flex flex-col h-[40vh] text-center justify-center">
            <p>No items in the wishlist.</p>
          </div>
        )}
        {/* //////////////////////MOB Start /////////////// */}
        {wishlist.length > 0 ? (
          <>
                  <span className="md:hidden flex justify-center uppercase border-b pb-2 w-full text-center">
                    Products
                  </span>
            {wishlist.map((product) => (
              <>
                <div className="md:hidden flex-col mt-5 w-full items-center flex pb-4 border-b">
                  <div className="flex flex-col items-center">
                    <div className="flex items-center flex-col mt-4 gap-5">
                      <button
                        className="bg-[#eeeeee] shrink-0 p-1  hover:bg-app_primary rounded-full"
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        onClick={() => handleRemove(product)}
                      >
                        <img
                          src={isHovered ? cancelw : cancel}
                          className="w-4 h-4"
                          alt=""
                        />
                      </button>
                      <img
                        src={product.imageUrls[0]}
                        className="w-20 h-20 my-4"
                        alt=""
                      />
                      <span className="break-words text-center py-4">{product.productName}</span>
                    </div>
                    
                    <td className="text-center  w-full py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">
                      {renderPrice(product.productPrice)}
                    </td>
                    <div className="flex gap-2 my-5 items-center">
                      <img src={inStock} className="w-4 h-5" alt="" />
                      <span className="text-gray-700">In stock</span>
                    </div>
                    <div
                      className={`${
                        cartItems.find((cartItem) => cartItem.id == product.id)
                          ? "!bg-black"
                          : "bg-app_primary"
                      }   cursor-pointer bg-app_primary w-full text-app_white group-hover:block`}
                    >
                      
                      <div className="flex justify-center">
                        {cartItems.find(
                          (cartItem) => cartItem.id == product.id
                        ) ? (
                          <Link to="/cart" className="h-full p-2 text-center">
                            View Cart
                          </Link>
                        ) : (
                          <span
                            className="h-full  p-2 text-center"
                            onClick={() => handleCart(product)}
                          >
                            Add to Cart
                          </span>
                        )}
                      </div>
                    </div>

                    {/*             <button
              type="button"
              className="uppercase my-2s inline-flex items-center gap-x-2 text-sm font-semibold  border border-transparent text-app_white bg-msky  p-2"
            >
              Add to cart
            </button> */}
                  </div>
                </div>
              </>
            ))}
            <button
              type="button"
              onClick={() => {
                navigate("/shop");
              }}
              className=" py-3 text-center px-2 flex md:hidden justify-center items-center gap-2 text-msky hover:text-black w-full mt-4 "
            >
              <span className="text-2xl leading-[0px]">&#8592;</span>
              back to shop
            </button>
          </>
        ) : (
          <div className="flex flex-col h-[40vh] text-center justify-center">
            <p>No items in the wishlist.</p>
          </div>
        )}

        {/* //////////////////////MOB END/////////////// */}
      </div>

      <Footer />
    </div>
  );
};

export default WhishList;
