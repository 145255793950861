import { Check, ChevronsUpDown } from "lucide-react";
import { cn } from "../../lib/utils";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList,
} from "../../component/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../component/ui/popover";
import fav from "../../assets/svg/favH.svg";
import favr from "../../assets/svg/favF.svg";

import product from "../../assets/images/mainProd.jpg";

import BrandPartner from "../../component/common/brandPartner";
import MegaMenu from "../../component/mega-menu";
import hamburger from "../../assets/svg/hamburger.svg";
import spinner from "../../assets/svg/spinner.svg";
import hamburgerb from "../../assets/svg/hamburgerb.svg";
import searchW from "../../assets/svg/mag-w.svg";
import searchb from "../../assets/svg/mag-b.svg";
import gridView from "../../assets/svg/menu2.svg";
import gridViewTwo from "../../assets/svg/menu2p.svg";


import { Sheet, SheetContent, SheetTrigger } from "../../component/ui/sheet";
import { Button } from "../../component/ui/button";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BrandChecksFilter from "../../component/brandsFilter";
import NavBar from "../../component/NavBar/NavBar";
import NavbarShops from "../../component/navBarShop/navBar";
import Footer from "../../component/Footer/Footer";
import cart from "../../assets/svg/cart.svg";
import moodHeart from "../../assets/svg/moodHeart.svg";

import AccordionCustom from "../../component/accordion/accordion";
import SignUp from "../../component/signup/signUp";
import { SERVER_URL } from "../../constants/apiLinks";
import ScrollToTop from "../../component/scrollToTop/scroll";
// redux
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../cartSlice";
import { RootState } from "../../store";
import { addToWishlist, removeFromWishlist } from "../../wishlistSlice";
import DialogBox from "../../component/myOwnDialogBox/MyDialog";
//redux end
// Product images start

// Product images End
function Shop() {
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate("/cart");
  };
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [isGrid, setIsGrid] = useState(false);
/*   const [isList, setIsList] = useState(false);
  const [isToCart, setIsToCart] = useState<{ [key: number]: boolean }>({}); */
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const [favorites, setFavorites] = useState<{ [key: number]: boolean }>({});
  const [products, setProducts] = useState<any[]>([]);
  const [resetProducts, setResetProducts] = useState<any[]>([]);
  const [loading,setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>(''); // For search input
  const [productName, setProductName] = useState<string>(''); // For search input
  const [mobSearch, setMobSearch] = useState<string>(''); // For search input
  const [currentPage, setCurrentPage] = useState<number>(1); // Current page state
  const [totalPages, setTotalPages] = useState<number>(1); // Total pages
  const [totalItems, setTotalItems] = useState<number>(1); // Total pages
  const [itemsPerPage,setItemsPerPage] =useState<number>(24);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

 /*  const handleFav = (productId: number) => {
    setFavorites((prevFavorites) => ({
      ...prevFavorites,

      [productId]: !prevFavorites[productId],
    }));
  }; */

/*   const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page on new search
  };
 */
// Function to extract the numeric price from the productPrice string
const extractPrice = (priceString: string): number => {
  const numericPrice = parseFloat(priceString.replace(/[^0-9.]/g, ""));
  return isNaN(numericPrice) ? 0 : numericPrice;
};

// Function to sort products by price from low to high
const sortByPriceLowToHigh = () => {
  const sortedProducts = [...products].sort(
    (a, b) => extractPrice(a.productPrice) - extractPrice(b.productPrice)
  );
  // Update state with sorted products
  setProducts(sortedProducts);
};

  // Function to sort products by price from high to low
  const sortByPriceHighToLow = () => {
    const sortedProducts = [...products].sort(
      (a, b) => extractPrice(b.productPrice) - extractPrice(a.productPrice)
    );
    setProducts(sortedProducts); // Update state with sorted products
  };

  const frameworks = [
    {
      value: "popular",
      label: "Sort by popularity",
    },
    {
      value: "averageRating",
      label: "Sort by average rating",
    },
    {
      value: "latest",
      label: "Sort by latest",
    },
    {
      value: "lowToHigh",
      label: "Sort by price: low to high",
    },
    {
      value: "highToLow",
      label: "Sort by price: high to lows",
    },
  ];
  const handleGrid = () => {
    setIsGrid(!isGrid);
  };



  /* const renderPrice = (priceText:any) => {
    const pricePattern = /(\$\d+\.\d+)\s+(\$\d+\.\d+)\s+\((.+?)\)/;
    const match = priceText.match(pricePattern);
  
    if (!match) {
      return<h1 className="text-app_primary">
      {priceText}</h1>; 
    }
  
    const [, originalPrice, salePrice, unit] = match; 
  
    return (
      <h1 className="text-center text-app_primary">
        <span className="line-through text-sm text-gray-500 mr-2">{originalPrice}</span>
        <span className=" mr-2">{salePrice}</span>
        <span className="">({unit})</span>
      </h1>
    );
  } */

/* const renderPrice = (priceText: any) => {
    const pricePattern = /(\$\d+\.\d+)\s+(\$\d+\.\d+)\s+\((.+?)\)/;
    const match = priceText.match(pricePattern);

    if (!match) {
      return <h1 className="text-app_primary">{priceText}</h1>; // Handle invalid format
    }

    const [, originalPrice, salePrice, unit] = match; // Destructure the matched groups

    return (
      <h1 className="text-center text-app_primary">
        <span className="line-through text-sm text-gray-500 mr-2">
          {originalPrice}
        </span>
        <span className=" mr-2">{salePrice}</span>
        <span className="">({unit})</span>
      </h1>
    );
  }; */
  const renderPrice = (priceText: any) => {
    // Match prices with commas and optional decimals
    const pricePattern = /^\$(\d{1,3}(,\d{3})*(\.\d{2})?)\s*(\$(\d{1,3}(,\d{3})*(\.\d{2})?))?\s*(\((.+?)\))?$/;
    const match = priceText.match(pricePattern);
  
    if (!match) {
      return <h1 className="text-app_primary">${priceText}</h1>; // Handle invalid format
    }
  
    const originalPrice = match[1]; // First price
    const salePrice = match[5]; // Sale price (optional)
  
    return (
      <h1 className="text-center text-app_primary">
        {salePrice ? (
          <>
            <span className="line-through text-sm text-gray-500 mr-2">${originalPrice}</span>
            <span className="mr-2">${salePrice}</span>
          </>
        ) : (
          <span className="mr-2">${originalPrice}</span>
        )}
      </h1>
    );
  };
  

async function fetchProducts() {
  try{
    setLoading(true)
    const response = await fetch(`${SERVER_URL}/api/user/product?search=${searchTerm}&page=${currentPage}&limit=${itemsPerPage}`);
    const data = await response.json();
    console.log("PR",data);
    
    setProducts(data.data);
    setResetProducts(data.data);
    setTotalPages(data.pagination?.pages);
    setTotalItems(data.pagination?.total);

  }
  catch(error){
console.log("ERROR",error);

  }
  finally{
    setLoading(false)
  }
  
}     

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    fetchProducts();
  }, [searchTerm, currentPage,itemsPerPage]);
  

  useEffect(()=>{
    if(value==="lowToHigh"){
      console.log("lowToHigh");
      sortByPriceLowToHigh();
    }
    else if(value==="highToLow"){
      console.log("highToLow");
      sortByPriceHighToLow();
    }
    else if(value==="latest"){
      console.log("latest");
      const sortedProducts = [...products].sort(
        (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
      setProducts(sortedProducts);
      setCurrentPage(1);
    }
    else if(value==="averageRating"){
      console.log("averageRating");
      setProducts(resetProducts);
    }
    else if(value==="popular"){
      console.log("popular");
    }
  },[value])
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };


  //Redux
  const dispatch = useDispatch();
  const cartItems = useSelector((state: RootState) => state.cart.items);
  const wishlist = useSelector((state: RootState) => state.wishlist.items);

  const handleCart = (product: any) => {
    if (cartItems[product.id]) {
    } else {
      dispatch(addToCart(product));
    }
    console.log(cartItems);
    console.log(cartItems.length);
  };

  // Handle Add to Wishlist
  
  const handleWishlist = (product: any) => {
    console.log("Wishlist Items:", wishlist);
    
    const isInWishlist = wishlist.some((item) => item.id === product.id);
    
    if (isInWishlist) {
      dispatch(removeFromWishlist(product));
      // toast.info("Product removed from wishlist");
    } else {
      dispatch(addToWishlist(product));
      // toast.success("Product added to wishlist");
    }
    
    // console.log("Wishlist Items:", JSON.stringify(wishlist,null,2));
  };

  useEffect(() => {
  }, [cartItems]);
  //redux end

  return (
    <div className="relative">
      <ScrollToTop/>
      {/* Nvbar section start */}
      <NavBar
        onAboutPress={() => navigate("/?section=aboutus")}
        onServicePress={() => navigate("/?section=service")}
      />
      {/* Nvbar section End */}
      {/* secondary Menu */}
          <div className="top-[70px] min-[400px]:top-[70px] min-[767px]:top-[73px] min-[768px]:top-[83px] min-[1440px]:top-[83px]  z-50 fixed w-full bg-white">
      <NavbarShops />
      <div className="flex gap-2 p-4 min-[1440px]:hidden items-center ">
            {/* mob menu start */}
            <Sheet>
              <SheetTrigger asChild>
                <button className="min-[1440px]:hidden inline-flex gap-2 items-center font-bold text-black text-[14px] font-Noto ">
                  <img src={hamburgerb} className="h-5 w-5" alt="" />
                  Menu
                </button>
              </SheetTrigger>
              <SheetContent
                side={"left"}
                className="no-scrollbar flex h-full w-full flex-col gap-3 overflow-scroll bg-gray-200 max-w-[300px] px-0"
              >
                <div className="flex flex-col gap-2 pt-10">
                  <div className="px-4">
                    <div className="flex items-stretch gap-4 p-0 m-0 overflow-hidden rounded  bg-app_bg_gray border-none outline-none ">
                      <input
                        type="text"
                        className="w-full bg-transparent px-3 my-0 py-3 border-none outline-none focus:border-none"
                        placeholder="Search"
												value={mobSearch}
                        onChange={(e)=>setMobSearch(e.target.value)}
                      />
                      <button type="button" className="flex items-center self-stretch flex-col w-20 justify-center   bg-msky h-auto">
                        <img src={searchW} className="h-7 w-7" alt="" 
												onClick={()=>{setSearchTerm(mobSearch)}}
												/>
                      </button>
                    </div>
                  </div>

                  <div className="flex flex-col gap-4">
                    <AccordionCustom />
                  </div>
                </div>
              </SheetContent>
    </Sheet>
            {/* Mobile Menu End */}
            {/* Cart on mobile screen start */}
            <button
              className="px-2  flex gap-1 cursor-pointer items-center"
              onClick={handleNavigation}
            >
              <img src={cart} className="w-4 h-4" alt="" />
              <span className="bg-app_primary text-app_white text-[12px] px-2">
                {cartItems.length}
              </span>
            </button>
            {/* Cart on mobile screen end */}
          </div>     
    </div>
   
    <div className="bg-app_bg_gray bg-opacity-100 h-[90px] min-[400px]:h-[120px]  min-[1440px]:h-[150px]"></div>
      {/* secondary Menu ENd */}
      {/* Product start */}
      <div className="no-scrollbar relative z-0 mx-auto flex h-full w-[95%] items-start gap-4 px-4 font-poppins md:w-[85%] pt-2 md:pt-10">
        <div className="z-50 hidden flex-[2] flex-col gap-2 lg:flex">
          <div className="flex items-stretch gap-4 p-0 m-0 overflow-hidden rounded  bg-app_bg_gray border-none outline-none ">
            <input
              type="text"
              className="w-full bg-transparent px-3 my-0 py-3 border-none outline-none focus:border-none"
              placeholder="Search your product..."
              value={mobSearch}
              onChange={(e)=>setMobSearch(e.target.value)}
            />
            <button type="button" onClick={()=>{setSearchTerm(mobSearch)}} className="flex items-center self-stretch flex-col w-20 justify-center   bg-msky h-auto"
            >
              <img src={searchW} className="h-7 w-7" alt="" />
            </button>
          </div>
          <section className="relative z-[2020202]">
            <MegaMenu />
          </section>
          <div className="flex items-center gap-3">
            <div className="h-[20px] w-1 bg-app_primary"></div>
            <span className="text-[13px]">Brands</span>
          </div>
          <BrandChecksFilter />
          <div className="flex flex-col gap-4"></div>
        </div>
        <div className="flex flex-[5] flex-col gap-5"> 
          <div className="flex flex-col items-center justify-between gap-2 border-y py-2 md:flex-row">
            <div className="flex items-stretch gap-2">
              {/* OverLay Filter start */}
              <Sheet>
                <SheetTrigger asChild>
                  <Button className="inline-flex items-center gap-3 rounded-none border text-app_dark_light shadow-none">
                    <img src={hamburger} className="h-5 w-5" alt="" />
                    Filter
                  </Button>
                </SheetTrigger>
                <SheetContent
                  side={"left"}
                  className="no-scrollbar flex h-full w-full flex-col gap-3 overflow-scroll bg-app_white"
                >
                  <div className="flex flex-col gap-2 pt-10">
                    <div className="flex items-center gap-4 rounded bg-app_bg_gray pr-2">
                      <input
                        type="text"
                        className="w-full bg-transparent px-3 py-3 border-none outline-none focus:border-none focus:outline-none"
                        placeholder="Search..."
                      />
                      <button>
                        <img src={searchb} className="h-7 w-7" alt="" />
                      </button>
                    </div>

                    <div className="mt-3 flex items-center gap-3">
                      <div className="h-[20px] w-1 bg-app_primary"></div>
                      <span className="text-[13px]">Brands</span>
                    </div>
                    <BrandChecksFilter />
                    <div className="flex flex-col gap-4"></div>
                  </div>
                </SheetContent>
              </Sheet>
              {/* OverLay Filter  */}

              {/* Product View type button start */}
              <div className="flex gap-2">
                <button
                  className={`flex w-10 items-center justify-center p-1 ${
                    isGrid ? "border border-app_primary" : "border"
                  }`}
                  onClick={handleGrid}
                >
                  {isGrid ? (
                    <img src={gridViewTwo} className="h-5 w-5" alt="" />
                  ) : (
                    <img src={gridView} className="h-5 w-5" alt="" />
                  )}
                </button>
           
              </div>

              {/* Product View type button End */}

              {/* Sorting Box start */}
              <Popover open={open} onOpenChange={setOpen}>
                <PopoverTrigger asChild>
                  <Button
                    role="combobox"
                    aria-expanded={open}
                    className="w-[240px] justify-between rounded-none border font-normal text-app_text hidden xl:flex shadow-none"
                  >
                    {value
                      ? frameworks.find(
                          (framework) => framework.value === value
                        )?.label
                      : "Default sorting"}
                      
                    <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-[240px] bg-white p-0">
                  <Command>
                    <CommandList>
                      <CommandEmpty>No framework found.</CommandEmpty>
                      <CommandGroup>
                        {frameworks.map((framework) => (
                          <CommandItem
                            key={framework.value}
                            value={framework.value}
                            onSelect={(currentValue) => {
                              setValue(
                                currentValue === value ? "" : currentValue
                              );
                              setOpen(false);
                            }}
                          >
                            <Check
                              className={cn(
                                "mr-2 h-4 w-4",
                                value === framework.value
                                  ? "opacity-100"
                                  : "opacity-0"
                              )}
                            />
                            {framework.label}
                          </CommandItem>
                        ))}
                      </CommandGroup>
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>
              {/* Sorting Box End */}
            </div>
            <div className="flex items-center gap-1 shrink-0">
              <span>View : </span>
              <span className={`ml-2 hover:cursor-pointer ${itemsPerPage==24?"text-app_primary":"text-app_black"}`} onClick={() =>{setItemsPerPage(24)}} >24</span>
              <span>/</span>
              <span className={` hover:cursor-pointer ${itemsPerPage==48?"text-app_primary":"text-app_black"}`} onClick={() =>{setItemsPerPage(48)}}>48</span>
             {/*  <button
              disabled={loading}
                onClick={() =>{handleViewItems()}}
                className={` ${itemsPerPage<totalItems ?"text-msky":"text-black" }`}
              >
                {itemsPerPage<totalItems ? "See All" : "See Less"}
              </button> */}
            </div>
            {/* Pagination (only show when not viewing all) */}
          </div>
          {/* Product start */}
          {loading?(<div className="flex flex-col h-[50vh] items-center justify-center">
            <img src={spinner} className="h-24 w-44" alt="Favorited" />
            </div>
            ):(
              <>
              {products&&products.length<1?(<div className="flex flex-col h-[50vh] items-center justify-center">
            <h1 className="font-semibold text-xl">No result found</h1>
            <h1 className="text-lg">Try different keyword</h1>
            </div>):(
          <div
            className={`grid grid-cols-1 gap-x-6 gap-y-4 ${
              isGrid ? "grid-cols-1" : "md:grid-cols-2 min-[1280px]:grid-cols-3"
            }`}
          >
            
            {products?.map((item) => {
              return (
                <div
                  key={item.id}
                  className={`group relative flex w-full flex-col self-start outline-gray-100 transition-all delay-100 duration-150 hover:shadow-md hover:outline hover:outline-2`}
                >
                  <div className="relative justify-center">
                    <img
                      src={item.imageUrls[0]}
                      className={`mx-auto mt-1 h-[240px] w-full min-w-[250px] shrink-0 bg-app_white ${
                        isGrid ? "max-w-[240px]" : ""
                      }`}
                      alt=""
                    />
<div
                                className={`${
                                  cartItems.find(
                                    (cartItem) => cartItem.id === item.id
                                  )
                                    ? "!bg-black"
                                    : ""
                                } absolute bottom-0 mt-4 hidden w-full cursor-pointer bg-app_primary text-app_white group-hover:block`}
                              >
                                <div className="flex justify-center">
                                  {cartItems.find(
                                    (cartItem) => cartItem.id === item.id
                                  ) ? (
                                    <Link
                                      to="/cart"
                                      className="h-full w-full py-3 text-center"
                                    >
                                      View Cart
                                    </Link>
                                  ) : (
                                    <span className="h-full w-full py-3 text-center" onClick={() => handleCart(item)}>
                                      Add to Cart
                                    </span>
                                  )}
                                </div>
                              </div>
                  </div>
{/*                   <div className="absolute right-1 top-1">
                    <button
                      className="rounded-full bg-slate-100 p-2"
                      onClick={() => handleFav(item.id)}
                    >
                      {favorites[item.id] ? (
                        <img
                          src={favr}
                          className="h-6 w-6"
                          alt="Not Favorited"
                        />
                      ) : (
                        <img src={fav} className="h-6 w-6" alt="Favorited" />
                      )}
                    </button>
                  </div> */}
                   <div className="absolute right-1 top-1">
                              <button
                                className="rounded-full bg-slate-100 p-2"
                                onClick={() => {handleWishlist(item);setIsDialogOpen(true);setProductName(item.productName);}}
                              >
                                <img
                                  // src={ wishlist.some((item) => item.id === item.id) ? favr : fav} // Show different icons for added/removed
                                  /* src={wishlist.some(
                                    (itemStored) => itemStored.id === item.id) ? favr : fav} // Show different icons for added/removed */
                                    src={wishlist.some(
                                    (itemStored) => itemStored.id === item.id) ? favr : fav} // Show different icons for added/removed
                                  className="h-6 w-6"
                                  alt={
                                    wishlist.some((item) => item.id === item.id)
                                      ? "Remove from wishlist"
                                      : "Add to wishlist"
                                  }
                                />
                              </button>
                            </div>
                  <div className="flex flex-col px-3 py-4">
                    {/* Preview Images start */}
                    <button
                      className={`flex items-center gap-2 ${
                        isGrid ? "justify-center" : ""
                      }`}
                    >
                      {item.imageUrls.map((prodImage:any, index:any) => {
                        // console.log(`${prodImage}-${index}`);
                        return (
                          <button
                            key={`${index}`}
                            onClick={() => setSelectedImage(prodImage)}
                          >
                            <img
                              src={prodImage}
                              className="h-10 w-10 shrink-0 border p-1"
                              alt=""
                            />
                          </button>
                        );
                      })}
                    </button>
                    {/* Preview Images End */}
                    <div className="group-[] flex flex-col items-center justify-center gap-1 py-4">
                      <span onClick={() => navigate(`/product-category/${item.category.slug}`)} className="group-hover:cursor-pointer group-hover:text-app_primary text-[12px] font-light italic text-app_dark_light">
                       {item.category?item.category.name:item.brand}
                      </span>
                      <span onClick={() => navigate(`/product/${item.id}`)} className="group-hover:cursor-pointer group-hover:text-app_primary text-center text-[15px] font-medium text-app_black">
                       {item.productName}
                      </span>
                    </div>
                    {/* Price start */}
                    <span className="text-center text-app_primary">
                     {item.productPrice&&renderPrice(item.productPrice)}
                    </span>
                    {/* Price End */}
                  </div>
                </div>
              );
            })}
          </div>
          )}</>
          )}
          {/* Product End */}
          {/* Pagination Controls */}
      <div className="flex space-x-1 max-w-[300px] md:max-w-full overflow-x-auto sm:justify-end my-6">
  <button
    onClick={() => handlePageChange(currentPage - 1)}
    disabled={currentPage === 1}
    className="px-3 py-1 border border-gray-300 md:min-w-10 md:min-h-10 rounded-r disabled:hidden"
  >
    Previous
  </button>

  {/* Show the first page and ellipsis if current page is far from it */}
  {currentPage > 3 && (
    <>
      <button
        onClick={() => handlePageChange(1)}
        className={`px-3 py-1 border border-gray-300 md:min-w-10 md:min-h-10 ${currentPage === 1 ? 'bg-[#F8F8F8] text-black' : 'bg-white'}`}
      >
        1
      </button>
      <span className="px-2">...</span>
    </>
  )}

  {/* Show a range of pages around the current page */}
  {[...Array(totalPages)]
    .map((_, pageIndex) => pageIndex + 1)
    .filter(
      (page) =>
        page === currentPage ||
        (page >= currentPage - 1 && page <= currentPage + 1)
    )
    .map((page) => (
      <button
        key={page}
        onClick={() => handlePageChange(page)}
        className={`px-3 py-1 border border-gray-300 md:min-w-10 md:min-h-10  ${currentPage === page ? 'bg-[#F8F8F8] text-black' : 'bg-white'}`}
      >
        {page}
      </button>
    ))}

  {/* Show the last page and ellipsis if current page is far from the end */}
  {currentPage < totalPages - 2 && (
    <>
      <span className="px-2">...</span>
      <button
        onClick={() => handlePageChange(totalPages)}
        className={`px-3 py-1 border border-gray-300 md:min-w-10 md:min-h-10 ${currentPage === totalPages ? 'bg-[#F8F8F8] text-black' : 'bg-white'}`}
      >
        {totalPages}
      </button>
    </>
  )}

  <button
    onClick={() => handlePageChange(currentPage + 1)}
    disabled={currentPage === totalPages}
    className="px-3 py-1 border border-gray-300 md:min-w-10 md:min-h-10 rounded-r disabled:cursor-not-allowed"
  >
    Next
  </button>
</div>
        </div>
      </div>
      {/* Product Ends */}
{/* //////////////////MODAL /////////////////// */}
<DialogBox isOpen={isDialogOpen}
    onClose={() => setIsDialogOpen(false)}
    back={true}
    >
      <div className="h-[20vh] flex flex-col justify-center items-center " >
      <div className="flex flex-col items-center  font-Noto">
<img src={moodHeart} alt="icon" className="w-12 h-12 mb-1"/>
      <p className="text-sm ">Status Updated In Wish List</p>
      <span className="text-sm">for</span>
      <p className="text-sm text-center">{productName}</p>
      <div className="flex flex-col w-full gap-2 pt-4 pb-10">
      <button type="button"  onClick={() => {
            navigate("/wishlist");
          }} className="px-4 py-1 rounded-sm bg-app_primary text-white hover:bg-msky">view wish list</button>
      <button type="button" onClick={()=>setIsDialogOpen(false)} className="px-4 py-1 rounded-sm bg-app_primary text-white hover:bg-msky">close</button>
      </div>
      </div>  
      </div>
</DialogBox>             
{/* //////////////////MODAL END /////////////////// */}
      {/* Brands partner start */}
      <BrandPartner />
      {/* Brands partner End */}

      {/* Sign Up section start */}
      <SignUp />
      {/* Sign Up section End */}

      {/* Footer start */}
      <Footer
        onAboutPress={() => navigate("/?section=aboutus")}
        onServicePress={() => navigate("/?section=service")}
      />

      {/* Selected Image view */}

      {selectedImage && (
        <div className="z-[1000000] fixed left-0 top-0 right-0 h-screen bg-gray-900/60 flex flex-col justify-center items-center p-4 gap-4">
          <button
            type="button"
            className="bg-red-500 fixed top-2 text-white rounded-sm p-3 self-end"
            onClick={() => setSelectedImage(null)}
          >
            close
          </button>
          <div className="flex-1 self-stretch grid place-content-center">
            <img
              src={selectedImage}
              alt={selectedImage}
              className="object-contain"
            />
          </div>
        </div>
      )}

      {/* Footer End */}
    </div>
  );
}

export default Shop;
